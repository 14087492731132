import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {SendEmailService} from '../services/send-email.service';
import { ToastrService } from 'ngx-toastr';
import { PasswordValidator } from '../validator/Password.validator';
import {AuthenticationService} from '../services/authentication.service';
import {EnvironmentalService} from '../services/environmental.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})

export class ResetPasswordComponent implements OnInit {
  submitted = false;
  pwdForm: FormGroup;
  isPasswordFocused: boolean;
  isConfirmPwdFocused: boolean;
  env: string;
  password: string;
  token: string;
  email: string;
  newPassword;
  confirmPassword;
  isPasswordReseted = false;
  isTokenAlive = false;
  constructor(private formBuilder: FormBuilder, private router: Router,
              private route: ActivatedRoute, public emailService: SendEmailService,
              private toastrService: ToastrService, private authService: AuthenticationService,
              public envService: EnvironmentalService) {
    this.isPasswordFocused = false;
    this.isConfirmPwdFocused = false;
  }
  ngOnInit() {
    this.route.queryParamMap.subscribe(param => {
      this.token = param.get('reset-password-token');
      this.env = param.get('env');
      this.env = atob(this.env);
      localStorage.setItem('env', this.env);
      this.email = localStorage.getItem('email');
      this.authService.validateUrl(this.email, this.token).subscribe(
        (data) => {
          if (data.isTokenAlive) {
            this.isTokenAlive = true;
          } else {
            this.isTokenAlive = false;
            this.toastrService.error('The link for changing the password has expired. Please try again', 'Error', {timeOut: 3000});
            this.router.navigate(['forgot-password'], { queryParamsHandling: 'preserve' });
          }
        },
        (err) => {
          this.toastrService.error('There is some problem', 'Unexpected Error', { timeOut: 3000});
        }
      );
    });
    this.pwdForm = this.formBuilder.group({
        newPassword: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required]]
      },
      {validator: PasswordValidator('newPassword', 'confirmPassword')
    });
  }
  get f() { return this.pwdForm.controls; }

  changePassword(value) {
    this.submitted = true;
    if (this.pwdForm.valid) {
        this.email = localStorage.getItem('email');
        this.emailService.savePassword(this.email, this.newPassword, this.token).subscribe((data) => {
          if (data.isPasswordReseted === true || data.isTokenAlive === true) {
            this.goHome();
          } else if (!data.isTokenAlive) {
            this.router.navigate(['forgot-password'], { queryParamsHandling: 'preserve' });
          }
        },
        (err) => {console.log('---------->' + err.status); }
        );
    } else {
      return;
    }
  }
  onFocus() {
      this.isPasswordFocused = true;
  }
  onFocusPwd() {
    this.isConfirmPwdFocused = true;
  }
  onFocusLoss() {
    this.isPasswordFocused = false;
  }
  onFocusLossPwd() {
    this.isConfirmPwdFocused = false;
  }
  goHome() {
    const env = localStorage.getItem('env');
    this.envService.checkEnv(env);
  }
}
