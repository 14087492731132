import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {SendEmailService} from '../services/send-email.service';
import {EnvironmentalService} from '../services/environmental.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  isUsernameFocused: boolean;
  data;
  emailForm: FormGroup;
  submitted = false;
  env: string;
  isEmailSent: false;
  constructor(private formBuilder: FormBuilder, private router: Router,
              private route: ActivatedRoute, public emailService: SendEmailService,
              public envService: EnvironmentalService) {
    this.isUsernameFocused = false;
  }


  ngOnInit() {
    this.emailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]]
    });
    this.route.queryParamMap.subscribe(data => {
      if (localStorage.getItem('env')) {
        this.env = localStorage.getItem('env');
      } else {
        this.env = this.envService.isBase64(data.get('env'));
      }
    });
  }

  get f() { return this.emailForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.emailForm.invalid) {
      return;
    }
  }

  sendEmail() {
    let email = this.emailForm.get('email').value;
    const env = this.env;
    localStorage.setItem('email', email);
    this.emailService.sendEmail(email, env).subscribe(
      (data) => {
        this.data = data;
        this.router.navigate(['check-mail'], { queryParams: {isEmailValid: data.isEmailSent, env: this.env}});
      },
      (err) => {
        console.error(err.status);
      }
    );
  }
  goHome() {
    let env = this.env;
    if (localStorage.getItem('env') === null) {
      env = this.env;
      this.envService.checkEnv(env);
    } else {
      env = localStorage.getItem('env');
      this.envService.checkEnv(env);
    }
  }
  onFocus() {
    this.isUsernameFocused = true;
  }
  onFocusLoss() {
    this.isUsernameFocused = false;
  }
}
