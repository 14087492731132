import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {AcknowledgementComponent} from './acknowledgement/acknowledgement.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {AuthGuard} from './guard/auth.guard';
import {HashLocationStrategy} from '@angular/common';

const ROUTES: Routes = [
  { path: '', redirectTo: 'forgot-password', pathMatch: 'full' },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'check-mail', component: AcknowledgementComponent },
  { path: 'reset-password', component: ResetPasswordComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES)],
  providers: [AuthGuard],
  exports: [RouterModule]
})
export class AppRoutingModule { }
