import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {environment} from '../config/environment';

@Injectable({
  providedIn: 'root'
})

export class SendEmailService {
  serverUrl = environment.sendEmailUrl;
  resetUrl = environment.resetPwdUrl;
  /* serverUrl = 'http://172.104.190.151:3080/forgot-password/get-reset-password-url';
   resetUrl = 'http://172.104.190.151:3080/forgot-password/update-new-password';*/
  constructor(private http: HttpClient, private router: Router) {}
    sendEmail(email: string, env: string): Observable<any> {
      return this.http.post(this.serverUrl, {email, env});
  }
  savePassword(email: string, password: string, token: string): Observable<any> {
    password = btoa(password);
    return this.http.put(this.resetUrl, {email, password, token });
  }
}
