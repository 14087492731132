import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {EnvironmentalService} from '../services/environmental.service';

@Component({
  selector: 'app-acknowledgement',
  templateUrl: './acknowledgement.component.html',
  styleUrls: ['./acknowledgement.component.scss']
})
export class AcknowledgementComponent implements OnInit {
  isEmailSent = false;
  env: string;
  email = localStorage.getItem('email');
  message = '';
  constructor(private router: Router, private route: ActivatedRoute,
              public envService: EnvironmentalService) {
  }
  ngOnInit() {
    this.route.queryParamMap.subscribe(data => {
      this.env = data.get('env');
    });
    this.route.queryParams.subscribe((data) => {

      if (JSON.parse(data.isEmailValid)) {
        this.isEmailSent = true;
        this.message = ' is associated with us. You should receive an email containing instruction on how to create a new password';
      } else  {
        this.isEmailSent = false;
        this.message = ' is not associated with us';
      }
    });
  }

  goHome() {
    let env = this.env;
    if (localStorage.getItem('env') === null) {
      env = this.env;
      this.envService.checkEnv(env);
    } else {
      env = localStorage.getItem('env');
      this.envService.checkEnv(env);
    }
  }
}
