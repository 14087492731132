import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {SendEmailService} from './send-email.service';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../config/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  updatePwdUrl = environment.validateToken;
  /*updatePwdUrl = 'http://172.104.190.151:3080/forgot-password/validate-token';*/
  constructor(private router: Router, private emailService: SendEmailService, private http: HttpClient) { }
  validateUrl(email: string, token): Observable<any> {
    return this.http.post(this.updatePwdUrl, {email, token});
  }
}
