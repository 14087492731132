import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentalService {

  constructor() { }
  /*Redirect user as per the environment variable*/
  checkEnv(env) {
    if (env === 'pstg') {
      window.location.href = 'https://pstg.erixis.io';
    } else if (env === 'stg') {
      window.location.href = 'https://stg.erixis.io';
    } else if (env === 'prod') {
      window.location.href = 'https://solarpulse.erixis.io';
    }
  }

  /*Encoding & Decoding env*/
  isBase64(env) {
    if (btoa(atob(env)) === env) {
      return env;
    } else {
      return atob(env);
    }
  }
}
