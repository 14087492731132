import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute, UrlTree, NavigationEnd} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';
import {SendEmailService} from '../services/send-email.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  token;
  email = localStorage.getItem('email');
  constructor(private router: Router, private authService: AuthenticationService,
              private actRoute: ActivatedRoute, private emailService: SendEmailService) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | UrlTree {
    this.token = state.root.queryParams['reset-password-token'];
    this.authService.validateUrl(this.email, this.token).subscribe(
      (data) => {
        if (data.isTokenAlive) {
          this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
              this.router.navigate(['reset-password']);
            }
          });
        } else {
          alert('Invalid URL');
        }
      },
    (err) => { console.log(err); }
    );
    return false;
  }
}
